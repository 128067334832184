import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';

const App = () => {
	return (
		<HelmetProvider>
			<BrowserRouter>
				<AppRoutes />
			</BrowserRouter>
		</HelmetProvider>
	);
};

export default App;
